var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-sm-flex justify-content-between align-items-center mb-4"},[_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"text-secondary",attrs:{"type":"arrow-left-circle","role":"button","size":"2rem"},on:{"click":_vm.back}}),_c('div',{staticClass:"d-flex flex-row"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-4",attrs:{"variant":"secondary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.back}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Presione ctrl+shift+s o cmd+shift+s'
                    ),expression:"\n                        'Presione ctrl+shift+s o cmd+shift+s'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.read_only,"size":"md"},on:{"click":_vm.save}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"save","size":"1rem"}}),(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Guardando...")])],1):_c('span',[_vm._v("Guardar")])],1)],1)],1)]),_c('b-col',{staticClass:"d-flex align-items-stretch",staticStyle:{"min-height":"70vh"},attrs:{"cols":"12","lg":"12"}},[_c('b-card',{staticClass:"mb-4 w-100"},[_c('div',[_c('b-form',{staticClass:"p-5"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('h3',{staticClass:"mb-2"},[_vm._v(" Datos de la unidad contenido ")]),_c('div',{staticClass:"text-gray mb-5"},[_vm._v(" Por favor registre los datos de la unidad contenido para continuar ")])])]),_c('b-row',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}]},[_c('b-col',{attrs:{"cols":"12","md":"3","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"codigo"}},[_vm._v("Código")]),_c('b-form-input',{attrs:{"id":"codigo","type":"text","placeholder":"Ej: 001","state":_vm.validateState('codigo'),"disabled":_vm.read_only},model:{value:(
                                        _vm.$v.unidadContenido.codigo.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.unidadContenido.codigo, "$model", $$v)},expression:"\n                                        $v.unidadContenido.codigo.$model\n                                    "}}),(
                                        !_vm.$v.unidadContenido.codigo.$anyError
                                    )?_c('div',{staticClass:"length-field mt-1"},[_vm._v(" ("+_vm._s(_vm.$v.unidadContenido.codigo.$model .length)+" / "+_vm._s(_vm.$v.unidadContenido.codigo.$params .maxLength.max)+") ")]):_vm._e(),(
                                        !_vm.$v.unidadContenido.codigo.maxLength
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Este campo debe tener máximo "+_vm._s(_vm.$v.unidadContenido.codigo.$params .maxLength.max)+" caracteres ")]):_vm._e(),(
                                        !_vm.$v.unidadContenido.codigo.required
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"9","lg":"9","xl":"9"}},[_c('b-form-group',[_c('label',{attrs:{"for":"descripcion"}},[_vm._v("Descripción")]),_c('b-form-input',{attrs:{"id":"descripcion","type":"text","placeholder":"Ej: Mi unidad contenido","state":_vm.validateState('descripcion'),"disabled":_vm.read_only},model:{value:(
                                        _vm.$v.unidadContenido.descripcion
                                            .$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.unidadContenido.descripcion
                                            , "$model", $$v)},expression:"\n                                        $v.unidadContenido.descripcion\n                                            .$model\n                                    "}}),(
                                        !_vm.$v.unidadContenido.descripcion
                                            .$anyError
                                    )?_c('div',{staticClass:"length-field mt-1"},[_vm._v(" ("+_vm._s(_vm.$v.unidadContenido.descripcion .$model.length)+" / "+_vm._s(_vm.$v.unidadContenido.descripcion .$params.maxLength.max)+") ")]):_vm._e(),(
                                        !_vm.$v.unidadContenido.descripcion
                                            .maxLength
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Este campo debe tener máximo "+_vm._s(_vm.$v.unidadContenido.descripcion .$params.maxLength.max)+" caracteres ")]):_vm._e(),(
                                        !_vm.$v.unidadContenido.descripcion
                                            .required
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e()],1)],1)],1)],1)],1)])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"d-flex flex-row"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-4",attrs:{"variant":"secondary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.back}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Presione ctrl+shift+s o cmd+shift+s'
                    ),expression:"\n                        'Presione ctrl+shift+s o cmd+shift+s'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.read_only,"size":"md"},on:{"click":_vm.save}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"save","size":"1rem"}}),(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Guardando...")])],1):_c('span',[_vm._v("Guardar")])],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }